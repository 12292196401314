<template>
  <div>
    <!--MAIN CONTAINER-->
    <div class="bg-white">
      <div
        v-if="(!selectedCampaign || !selectedCampaign.value) && !showBanner"
        class="flex justify-center mt-40"
      >
        <div class="text-center space-y-2 w-120">
          <p class="text-md font-medium">Select a campaign to continue</p>
          <p class="text-sm">
            Select a campaign from the dropdown located in the top right corner to get keyword suggestions.
          </p>
        </div>
      </div>

      <div
        v-if="!isLoading && selectedCampaign && !datedSuggestions?.upToDateSuggestions && !showBanner"
        class="mt-40"
      >
        <empty-state>
          <div>
            <p class="text-md font-medium">No suggestions found</p>
          </div>
        </empty-state>
      </div>

      <div v-if="isLoading">
        <brightbid-loader
          class="mt-40"
          size="100"
        />
      </div>

      <!-- TITLE  -->
      <div v-if="!isLoading && !pageLoading">
        <div class="flex">
          <div :class="isEditPanelOpen ? 'new-width' : 'w-full'">
            <!-- SELECT BANNER  -->
            <div class="sticky top-0 z-10 bg-white">
              <div class="flex justify-between mx-8 pt-4 bg-white">
                <div
                  v-if="selectedCampaign"
                  class="flex gap-x-8 items-center"
                >
                  <h4 class="h4">Suggestions</h4>
                  <autopilot-button
                    v-if="!isAllCampaignsSelected"
                    :enabled="autopilotStatus"
                    :disabled="isLoading && isAllCampaignsSelected"
                    @click="toggleAutopilot"
                  />
                </div>
                <div
                  v-if="!showBanner && selectedCampaign && datedSuggestions?.upToDateSuggestions"
                  class="flex gap-x-8"
                >
                  <div class="flex gap-x-1 items-center relative">
                    <ic-sort />
                    <span class="text-bb-text-default"> Sort by</span>
                    <span
                      class="font-extrabold cursor-pointer"
                      @click="showSortBy = true"
                      >{{ getLabelByValue(sortCriteria) }}</span
                    >
                    <dropdown-arrow
                      :direction="translateDirection"
                      @click="toggleDirection"
                    />

                    <div
                      v-if="showSortBy"
                      class="absolute right-0 top-0 mt-10 w-40 bg-white rounded-md font-normal z-10 shadow-md overflow-y-auto max-h-52"
                    >
                      <div
                        v-for="metric in metrics"
                        :key="metric.label"
                        class="py-2 px-4 cursor-pointer hover:bg-bb-secondary-purple"
                        @click.stop.prevent="sortBy(metric.value)"
                      >
                        <p>{{ metric.label }}</p>
                      </div>
                    </div>
                  </div>
                  <merge-button-round
                    :disabled="!canUpdateKws || autopilotStatus"
                    button-type="secondary"
                    class="my-2"
                    @click="getSuggestions()"
                  >
                    Get new Suggestions
                    <template #left-icon>
                      <ic-refresh
                        :size="16"
                        class="my-auto"
                      />
                    </template>
                  </merge-button-round>
                </div>
              </div>

              <div
                v-if="selectedKeywords.length !== 0"
                class="w-full h-18 my-2 px-8 flex items-center justify-between bg-bb-secondary-purple"
              >
                <div class="flex gap-4">
                  <CheckBox
                    indeterminate
                    :disabled="selectedKeywords.length === 0"
                    :value="selectedKeywords.length !== 0"
                    input-id="checkbox"
                    input-name="checkbox"
                    @input="clearSelection"
                  />
                  <div>
                    <p class="font-medium text-md">{{ selectedKeywords.length }} Selected</p>
                  </div>
                </div>
                <div
                  v-if="!isEditPanelOpen"
                  class="flex gap-4"
                >
                  <MergeButtonRound
                    brightbid
                    :disabled="sendingRequest"
                    :button-type="'secondary'"
                    class="w-full"
                    @click="openEditPanel"
                  >
                    <div class="flex gap-2 items-center">
                      <ic-edit :size="16" />
                      Edit
                    </div>
                  </MergeButtonRound>
                  <MergeButtonRound
                    brightbid
                    :disabled="sendingRequest"
                    :button-type="'secondary'"
                    class="w-full"
                    @click="submitOriginalSuggestions('reject')"
                  >
                    <div class="flex gap-2 items-center">
                      <ic-x-circle
                        :size="16"
                        class="text-bb-error"
                      />
                      Reject
                    </div>
                  </MergeButtonRound>
                  <MergeButtonRound
                    brightbid
                    :disabled="sendingRequest"
                    class="w-full"
                    @click="submitOriginalSuggestions('accept')"
                  >
                    <div class="flex gap-2 items-center">
                      <ic-check :size="16" />
                      Accept
                    </div>
                  </MergeButtonRound>
                </div>
              </div>
            </div>

            <div
              v-if="selectedCampaign && datedSuggestions?.upToDateSuggestions"
              class="flex flex-col pt-6 gap-y-4 px-8"
            >
              <div
                v-if="autopilotStatus"
                class="flex flex-col md:flex-row w-full gap-4 p-4 bg-purple-0 rounded-md border border-bb-brand-purple shadow-bb-shadow"
              >
                <ic-stars
                  size="24"
                  class="text-bb-brand-purple flex-none"
                />
                <div class="flex flex-col gap-1">
                  <p class="h5">Autopilot is running...</p>
                  <p class="mr-4">
                    You can only manage Paused keywords. You can find autopilot actions related to Positive and Negative
                    keywords in the Activity History.
                  </p>
                </div>
                <div class="my-auto ml-auto whitespace-no-wrap">
                  <MergeButtonRound
                    brightbid
                    @click="$emit('go-to-activity-history')"
                  >
                    Check Activity History
                  </MergeButtonRound>
                </div>
              </div>

              <div
                v-if="!showBanner"
                class="flex items-center justify-end"
              >
                <p>
                  {{
                    datedSuggestions !== null
                      ? datedSuggestions.START.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
                      : ''
                  }}
                  -
                  {{
                    datedSuggestions !== null
                      ? datedSuggestions.END.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
                      : ''
                  }}
                </p>
              </div>
            </div>

            <div
              v-if="!showBanner"
              class="space-y-8 px-8 pb-40"
            >
              <!-- card component -->
              <keyword-suggestion-card
                v-for="suggestion in datedSuggestions.upToDateSuggestions"
                :key="suggestion.id"
                :keyword-suggestion="suggestion"
                :selected-keywords="selectedKeywords"
                :currency="currency"
                @select-keyword="selectKeyword(suggestion)"
              />
            </div>
          </div>

          <!-- edit panel -->
          <div
            v-if="isEditPanelOpen"
            class="edit-keywords-panel border-l sticky z-20 bg-white right-0 top-0 mt-2"
          >
            <edit-panel
              keep-alive
              :selected-keywords="selectedPositiveAndNegative"
              @close="close"
              @submit="submitUpdatedSuggestions"
            />
          </div>
        </div>
      </div>

      <div
        v-if="!pageLoading && !isLoading && showBanner"
        class="px-10 py-6 w-full container-banner"
      >
        <NoCampaignsBanner
          v-if="!hasEnabledCampaigns"
          :context="context"
          @submit="$emit('go-to-campaign-settings')"
        />
        <NoKwsBanner
          v-else-if="!hasKwSuggestions"
          :disabled="!canUpdateKws || autopilotStatus"
          :context="context"
          @submit="getSuggestions()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import Toast from '@/components/shared/Toast.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import AutopilotButton from '@/components/btn/AutopilotButton.vue'
import IcEdit from '@/components/icon/ic-edit.vue'
import IcSort from '@/components/icon/ic-sort.vue'
import IcXCircle from '@/components/icon/ic-x-circle.vue'
import KeywordSuggestionCard from '@/components/card/KeywordSuggestionCard.vue'
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow.vue'
import IcCheck from 'vue-material-design-icons/Check.vue'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import EditPanel from '@/views/keywords_suggestions/EditPanel.vue'
import EmptyState from '@/components/brightbid_illustrations/EmptyState.vue'
import NoCampaignsBanner from '@/views/keywords_suggestions/NoCampaignsBanner.vue'
import NoKwsBanner from '@/views/keywords_suggestions/NoKwsBanner.vue'
import IcRefresh from '@/components/icon/brightbid/ic-refresh.vue'

const MAX_SELECTED_KEYWORDS = 15
const METRICS = [
  { label: 'Cost', value: 'metrics_cost' },
  { label: 'Clicks', value: 'metrics_clicks' },
  { label: 'CPA', value: 'metrics_cpa' },
  { label: 'CPC', value: 'metrics_cpc' },
  { label: 'Conversions', value: 'metrics_conversions' },
  { label: 'CTR', value: 'metrics_ctr' },
]

export default {
  name: 'KeywordSuggestionsView',
  components: {
    EmptyState,
    IcCheck,
    DropdownArrow,
    KeywordSuggestionCard,
    IcXCircle,
    EditPanel,
    IcSort,
    IcEdit,
    AutopilotButton,
    CheckBox,
    BrightbidLoader,
    MergeButtonRound,
    IcStars,
    NoCampaignsBanner,
    NoKwsBanner,
    IcRefresh,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    pageLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedCampaign: null,
      currency: null,
      suggestions: [],
      selectedKeywords: [],
      modifiedSuggestions: {},
      isLoading: false,
      autopilotStatus: false,
      sendingRequest: false,
      showSortBy: false,
      isEditPanelOpen: false,
      isAscending: false, // Flag to track sorting order
      sortCriteria: 'metrics_cost', // Current sorting criteria
      metrics: METRICS,
      canUpdateKws: false,
    }
  },
  computed: {
    isAllCampaignsSelected() {
      return this.selectedCampaign?.value === 'all-campaigns'
    },
    sortedSuggestions() {
      // USE FAST SORT
      if (!this.sortCriteria) {
        return this.suggestions // No sorting criteria, return original array
      }

      return this.suggestions.slice().sort((a, b) => {
        const valueA = a[this.sortCriteria]
        const valueB = b[this.sortCriteria]

        if (this.isAscending) {
          return valueA - valueB
        } else {
          return valueB - valueA
        }
      })
    },
    translateDirection() {
      return this.isAscending ? 'up' : 'down'
    },
    selectedPositiveAndNegative() {
      return this.selectedKeywords.filter(kw => kw.suggestion_type !== 'pause')
    },
    keywordsToSend() {
      return this.selectedKeywords.map(kw => {
        return {
          id: kw.id,
          type: this.getKeywordType(kw.suggestion_type),
          text:
            this.modifiedSuggestions[kw.id] ||
            (kw.suggestion_type === 'pause' ? kw.keyword_text : kw.searchTermView_searchTerm),
          match_type: kw.match_type.toUpperCase(),
          campaign_id: kw.campaign_id,
        }
      })
    },
    datedSuggestions() {
      if (!this.sortedSuggestions || this.sortedSuggestions.length === 0) return null
      const dateRanges = {}

      this.sortedSuggestions.map(item => {
        const [startDate, endDate] = item.data_date_range.split(', ')

        // add start_date and end_date fields in the suggestion objects
        item.start_date = startDate
        item.end_date = endDate

        if (!dateRanges[endDate]) {
          // create a end date field in the dateRanges object
          dateRanges[endDate] = [startDate]
          return item
        }

        if (!dateRanges[endDate].includes(startDate)) {
          // if end date field already exists, add start date to the array
          dateRanges[endDate].push(startDate)
          return item
        }
      })

      // list the end dates
      const endDates = Object.keys(dateRanges)

      // get the latest end date
      const latestEndDate = endDates.reduce((latest, current) => {
        return new Date(latest) > new Date(current) ? latest : current
      })

      let latestStartDate = ''

      // get the latest start date
      if (dateRanges[latestEndDate].length > 1) {
        // get the latest start date
        latestStartDate = dateRanges[latestEndDate].reduce((latest, current) => {
          return new Date(latest) > new Date(current) ? latest : current
        })
      } else {
        latestStartDate = dateRanges[latestEndDate][0]
      }

      const START = new Date(latestStartDate)
      const END = new Date(latestEndDate)

      const upToDateSuggestions = []
      const outdatedSuggestions = []

      this.sortedSuggestions.map(item => {
        // filter the up to date and outdated suggestions
        if (START <= new Date(item.start_date) && END >= new Date(item.end_date)) {
          upToDateSuggestions.push(item)
        } else {
          outdatedSuggestions.push(item)
        }
      })

      return { upToDateSuggestions, outdatedSuggestions, START, END }
    },
    hasEnabledCampaigns() {
      return this.context?.keyword_suggestion_enabled_campaigns?.length > 0
    },
    hasKwSuggestions() {
      return this.suggestions?.length > 0
    },
    showBanner() {
      return !this.hasEnabledCampaigns || !this.hasKwSuggestions
    },
  },
  watch: {
    'context.selected_campaign': {
      handler: async function (newVal) {
        if (newVal?.value === null || !newVal) {
          this.selectedCampaign = newVal
          this.clearSelection()
          this.suggestions = []
          this.currency = null
          return
        }
        if (newVal.value === this.selectedCampaign?.value) return

        this.selectedCampaign = newVal
        this.isLoading = true
        this.autopilotStatus = newVal.suffix || false

        const { suggestions, currency } = await this.getKeywordSuggestions()

        this.suggestions = suggestions
        this.currency = currency

        this.clearSelection()
        this.canUpdateKws = this.checkCanUpdateKws()

        this.isLoading = false
      },
    },
  },
  methods: {
    async getKeywordSuggestions() {
      const allCampaignIds = structuredClone(this.context.keyword_suggestion_enabled_campaigns).map(item => item.value)
      const campaignIds =
        this.selectedCampaign.value === 'all-campaigns' ? allCampaignIds.join(',') : this.selectedCampaign.value
      const {
        data: { currency, suggestions_negative, suggestions_pause, suggestions_positive },
      } = await this.$http.get(
        `/search/site/${this.context.site_id}/keyword-suggestions?google_campaign_ids=${campaignIds}`,
      )

      const suggestions = [
        ...suggestions_negative.map(s => ({ ...s, suggestion_type: 'negative' })),
        ...suggestions_pause.map(s => ({ ...s, suggestion_type: 'pause' })),
        ...suggestions_positive.map(s => ({ ...s, suggestion_type: 'positive' })),
      ]

      return { suggestions, currency }
    },
    async refreshKeywords() {
      const { suggestions, currency } = await this.getKeywordSuggestions()

      this.suggestions = suggestions
      this.currency = currency
    },

    async toggleAutopilot() {
      try {
        if (this.isAllCampaignsSelected) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: 'Please select a campaign to be able to toggle Autopilot',
              type: 'error',
            },
          })
          return
        }
        this.isLoading = true
        const status = !this.autopilotStatus
        const payload = {
          user_email: this.context.user.email,
          enable_auto_pilot: status,
        }
        await axios.post(
          `/search/site/${this.context.site_id}/campaign/${this.selectedCampaign.value}/keyword-suggestions/toggle-auto-pilot`,
          payload,
        )
        if (status === false) {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Autopilot disabled!',
              type: 'success',
            },
          })
          this.selectedCampaign.suffix = status
        } else {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Autopilot enabled!',
              type: 'success',
            },
          })
        }
        this.autopilotStatus = status
        this.isLoading = false
        this.selectedCampaign.suffix = status
      } catch (e) {
        this.error = e.response.data.error
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `${this.error}`,
            type: 'error',
          },
        })
        this.isLoading = false
      }
    },
    getKeywordType(type) {
      const typeMap = {
        pause: 'paused_kws',
        positive: 'pos_kws',
        negative: 'neg_kws',
      }

      return typeMap[type] || null
    },
    getSuggestionText(suggestion) {
      return (
        this.modifiedSuggestions[suggestion.id] ||
        (suggestion.suggestion_type === 'pause' ? suggestion.keyword_text : suggestion.searchTermView_searchTerm)
      )
    },
    updateSuggestionText(suggestion, text) {
      this.$set(this.modifiedSuggestions, suggestion.id, text)
    },
    selectKeyword(suggestion) {
      const kwIndex = this.selectedKeywords.findIndex(kw => kw.id === suggestion.id)
      if (kwIndex !== -1) {
        this.selectedKeywords.splice(kwIndex, 1)
        return
      }

      if (this.selectedKeywords.length === MAX_SELECTED_KEYWORDS) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Editing limit reached',
            message: `Only ${MAX_SELECTED_KEYWORDS} suggestions can be edited at a time. Accepting or rejecting is still available.`,
            type: 'success',
          },
        })
        return
      }

      this.selectedKeywords.push(suggestion)
    },
    async submitUpdatedSuggestions(suggestions) {
      // get the selected paused suggestions
      const selectedPausedSuggestions = this.keywordsToSend
        .filter(kw => kw.type === 'paused_kws')
        .map(keyword => {
          return {
            id: keyword.id,
            type: keyword.type,
            original_type: keyword.type,
            text: keyword.text,
            match_type: keyword.match_type.toUpperCase(),
            campaign_id: keyword.campaign_id,
          }
        })

      // get the new suggestions
      const newSuggestions = suggestions.map(suggestion => {
        return {
          id: suggestion.id,
          original_type: this.getKeywordType(suggestion.original_type),
          type: this.getKeywordType(suggestion.suggestion_type),
          text: suggestion.text,
          match_type: suggestion.match_type.toUpperCase(),
          campaign_id: suggestion.campaign_id,
        }
      })

      const sortedNewSuggestions = this.sortSuggestionByCampaign(newSuggestions)
      const sortedPausedSuggestions = this.sortSuggestionByCampaign(selectedPausedSuggestions)

      sortedNewSuggestions.forEach(campaign => {
        this.acceptSuggestions(campaign.suggestions, campaign.campaign_id, true)
      })
      sortedPausedSuggestions.forEach(campaign => {
        this.acceptSuggestions(campaign.suggestions, campaign.campaign_id, false)
      })
    },
    async submitOriginalSuggestions(action) {
      const suggestions = this.keywordsToSend.map(keyword => {
        return {
          id: keyword.id,
          original_type: keyword.type,
          type: keyword.type,
          text: keyword.text,
          match_type: keyword.match_type.toUpperCase(),
          campaign_id: keyword.campaign_id,
        }
      })

      const sortedSuggestions = this.sortSuggestionByCampaign(suggestions)
      sortedSuggestions.forEach(campaign => {
        if (action === 'accept') {
          this.acceptSuggestions(campaign.suggestions, campaign.campaign_id, false)
        }
        if (action === 'reject') {
          this.rejectSuggestions(campaign.suggestions, campaign.campaign_id)
        }
      })
    },
    sortSuggestionByCampaign(suggestions) {
      const result = []
      suggestions.forEach(suggestion => {
        let campaign = result.find(c => c.campaign_id === suggestion.campaign_id)
        if (!campaign) {
          campaign = {
            campaign_id: suggestion.campaign_id,
            suggestions: [],
          }
          result.push(campaign)
        }
        campaign.suggestions.push(suggestion)
      })
      return result
    },
    async acceptSuggestions(suggestions, campaign_id, isEdited) {
      this.sendingRequest = true
      this.$emit('set-page-loading', true, 'KEYWORD_SUGGESTIONS')
      const acceptURL = isEdited
        ? 'keyword-suggestions/edit-accept-bulk'
        : `campaign/${campaign_id}/keyword-suggestions/accept-bulk`

      try {
        const response = await axios.post(`/search/site/${this.context.site_id}/${acceptURL}`, {
          user_email: this.context.user.email,
          kws: suggestions,
        })

        this.sendingRequest = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: response.message,
            type: 'success',
          },
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to accept the keyword suggestions',
            type: 'error',
          },
        })
        this.sendingRequest = false
      }

      this.clearSelection()
      await this.refreshKeywords()
      this.$emit('refetch-activity-history')
      this.$emit('set-page-loading', false, 'KEYWORD_SUGGESTIONS')
    },
    async rejectSuggestions(suggestions, campaign_id) {
      this.sendingRequest = true
      this.$emit('set-page-loading', true, 'KEYWORD_SUGGESTIONS')
      try {
        const response = await axios.post(
          `/search/site/${this.context.site_id}/campaign/${campaign_id}/keyword-suggestions/reject-bulk`,
          {
            user_email: this.context.user.email,
            kws: suggestions,
          },
        )

        this.sendingRequest = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: response.message,
            type: 'success',
          },
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to reject the keyword suggestions',
            type: 'error',
          },
        })
        this.sendingRequest = false
      }
      this.clearSelection()
      await this.refreshKeywords()
      this.$emit('refetch-activity-history')
      this.$emit('set-page-loading', false, 'KEYWORD_SUGGESTIONS')
    },
    openEditPanel() {
      if (this.selectedPositiveAndNegative.length === 0) return
      this.isEditPanelOpen = true
    },
    clearSelection() {
      if (this.selectedKeywords.length === 0) return
      this.selectedKeywords = []
      this.isEditPanelOpen = false
    },
    close() {
      this.isEditPanelOpen = false
    },
    sortBy(criteria) {
      this.showSortBy = false
      this.sortCriteria = criteria
    },
    getLabelByValue(metric_value) {
      return this.metrics.find(metric => metric.value === metric_value)?.label
    },
    toggleDirection() {
      this.isAscending = !this.isAscending
    },
    async getSuggestions() {
      this.isLoading = true
      try {
        await this.$http.post(`/search/site/${this.context.site_id}/generate-keyword-suggestions`, {
          use_task: true,
        })

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Great, new suggestions will be generated!',
            message: 'This may take a few minutes. Feel free to leave the page and return later.',
            type: 'success',
          },
        })
        this.storeKwsStoredData()
        this.canUpdateKws = false
      } catch (e) {
        this.removeKwsStoredData()
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to get new keyword suggestions',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    retrieveKwsStoredData() {
      const data = localStorage.getItem('keywordSuggestions')
      return data ? JSON.parse(data) : {}
    },
    storeKwsStoredData() {
      let data = this.retrieveKwsStoredData()
      data[this.context.site_id] = {
        timeGenerated: new Date().getTime(),
      }
      localStorage.setItem('keywordSuggestions', JSON.stringify(data))
    },
    removeKwsStoredData() {
      let data = this.retrieveKwsStoredData()
      delete data[this.context.site_id]
      localStorage.setItem('keywordSuggestions', JSON.stringify(data))
    },
    checkCanUpdateKws() {
      // check if the time elapsed is less than 2 minutes from previous generation
      const storedData = this.retrieveKwsStoredData()
      const lastTimeKwsGeneration = storedData[this.context.site_id]
        ? storedData[this.context.site_id]?.timeGenerated
        : 0
      const timeElapsed = new Date().getTime() - parseInt(lastTimeKwsGeneration)
      return timeElapsed > 120000
    },
  },
}
</script>

<style scoped>
.main-container {
  overflow-y: auto;
  height: calc(100vh - 211px);
}

.btn-autopilot {
  background: linear-gradient(93deg, #84fae4 -45.04%, #6366fa 67.26%);
}

.max-h-52 {
  max-height: 13rem;
}

.edit-keywords-panel {
  width: 280px;
  min-width: 280px;
  height: calc(100vh - 170px);
}

.new-width {
  width: calc(100% - 280px);
}
</style>
